.App-body {
    background: linear-gradient(rgb(2, 41, 79), rgb(9, 14, 16));
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

@media(min-width: 0em)  {
    .timeline-date {
        width: 100%;
        padding-top: 0 !important;
        padding-bottom: 5px !important;
    }
}

@media(min-width: 30em)  {
    .timeline-date {
        padding: 10px;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.btn::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    background: transparent;
    border: 1px solid transparent;
}

.btn::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background: transparent;
    border: none;
}

.btn:hover::before {
    animation: animateBefore 0.6s linear forwards;
}

.btn:hover::after {
    animation: animateAfter 0.5s linear forwards;
    animation-delay: 0.6s;
}

@keyframes animateBefore {
    0% {
        width: 0;
        height: 0;
        border-bottom: none;
        border-right: none;
        border-top: none;
        border-left: none;
    }

    50% {
        width: 0;
        height: 100%;
        border-top: none;
        border-right: 1px solid;
        border-bottom: none;
        border-left: none;
    }

    100% {
        width: 99.5%;
        height: 100%;
        border-top: 1px solid;
        border-right: 1px solid;
        border-bottom: none;
        border-left: none;
    }
}

@keyframes animateAfter {
    0% {
        width: 0;
        height: 0;
        border-bottom: none;
        border-right: none;
        border-top: none;
        border-left: none;
    }

    100% {
        width: 0;
        height: 100%;
        border-bottom: none;
        border-right: none;
        border-top: none;
        border-left: 1px solid;
    }
}
